import './style.css'
import * as THREE from 'three'
import { gsap } from 'gsap'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

// Canvas
const canvas = document.querySelector('canvas.webgl')

canvas.style.background = 'url(/ressources/placeholder.png) center center no-repeat'
canvas.style.backgroundSize = 'cover'

// Scene
const scene = new THREE.Scene()
let isRoomLoaded = false
let creativeBench = null
let workingDesk   = null
const creativeBenchGlow = []
const workingDeskGlow   = []
let intersectingWith = null
let isIntersectingZoomActive = false


// Handle zoom animation on intersect
const onIntersection = () => {
	const roomObject = scene.getObjectByName('room')
	if (isIntersectingZoomActive) {
		isIntersectingZoomActive = false

		gsap
			.to(roomObject.rotation, {
				x: Math.PI / 12,
				y: -Math.PI / 6,
				z: 0,
				duration: 1
			})

		gsap
			.to(roomObject.position, {
				x: 0,
				y: 0,
				z: 0,
				duration: 1
			})
		return
	}
	if (intersectingWith === 'creativeBench') {
		gsap
			.to(roomObject.rotation, {
				//x: Math.PI / 6,
				x: 0,
				y: 0,
				z: 0,
				duration: 1
			})
		gsap
			.to(roomObject.position, {
				x: -2,
				y: .4,
				z: 9,
				duration: 1
			})
		isIntersectingZoomActive = true
	} else if (intersectingWith === 'workingDesk') {
		gsap
			.to(roomObject.rotation, {
				x: Math.PI / 6,
				y: -Math.PI / 4,
				z: 0,
				duration: 1
			})
		gsap
			.to(roomObject.position, {
				x: 1,
				y: -.5,
				z: 6,
				duration: 1
			})
		isIntersectingZoomActive = true
	}else {
		return
	}

	setTimeout(() => {
		window.open(window.location.origin + '/work.html#' + intersectingWith, '_blank')
	}, 1500)
}







/**
* Sizes & Cursor
*/
const sizes = {
	width: window.innerWidth,
	height: window.innerHeight
}
const cursor = {
	x: 0,
	y: 0
}
window.addEventListener('resize', () => {
	// Update sizes
	sizes.width = window.innerWidth
	sizes.height = window.innerHeight

	// Update camera
	camera.aspect = sizes.width / sizes.height
	camera.updateProjectionMatrix()

	// Update renderer
	renderer.setSize(sizes.width, sizes.height)
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

window.addEventListener('mousemove', (event) => {
	cursor.x = (event.clientX / sizes.width) * 2 - 1
	cursor.y = -(event.clientY / sizes.height) * 2 + 1
}, false)

document.addEventListener('touchend', (event) => {
	cursor.x = (event.changedTouches[0].clientX / sizes.width) * 2 - 1
	cursor.y = -(event.changedTouches[0].clientY / sizes.height) * 2 + 1

	onIntersection()
}, false)

window.addEventListener('keydown', (event) => {
	const roomObject = scene.getObjectByName('room')
	if((event.key === 'Escape' || event.key === 'Esc') && isIntersectingZoomActive) {
		onIntersection()
	}
}, false)

window.addEventListener('click', onIntersection)

/**
* Camera
*/
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 2, 10)
scene.add(camera)

// DRACO
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/')
dracoLoader.setDecoderConfig({ type: 'js' })
dracoLoader.preload()

const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)


// Models & lights
gltfLoader.load(
	'/models/mur.gltf',
	(gltf) => {
		const room = gltf.scene
		room.name = 'room'
		room.rotation.y = -Math.PI / 6
		room.rotation.x = Math.PI / 12
		room.scale.set(.5, .5, .5)
		scene.add(room)
		isRoomLoaded = true
		canvas.style.background = null
		workingDesk = scene.getObjectByName('bureau')
		creativeBench = scene.getObjectByName('roller')

		// const axesHelper = new THREE.AxesHelper(3)
		// axesHelper.position.y += 3
		// room.add(axesHelper)

		// Generate glowiness on every object
		const customMaterial = new THREE.ShaderMaterial({
			uniforms:
			{
				"c": { type: "f", value: 1.0 },
				"p": { type: "f", value: 1.4 },
				glowColor: { type: "c", value: new THREE.Color(0xffff00) },
				viewVector: { type: "v3", value:  camera.position }
			},
			vertexShader: document.getElementById('vertexShader').textContent,
			fragmentShader: document.getElementById('fragmentShader').textContent,
			side: THREE.FrontSide,
			blending: THREE.AdditiveBlending,
			transparent: true
		})
		workingDesk.traverse((object) => {
			if(object.type != 'Mesh') return

			const part = object.clone()
			part.name += '_glow'
			part.material = customMaterial.clone()
			part.visible = false
			
			workingDeskGlow.push(part)
			object.parent.add(part)
		})
		creativeBench.traverse((object) => {
			if(object.type != 'Mesh') return

			const part = object.clone()
			part.name += '_glow'
			part.material = customMaterial.clone()
			part.visible = false

			creativeBenchGlow.push(part)
			object.parent.add(part)
		})
	}
)

const ambientLight = new THREE.AmbientLight(0xffffff, 1)
scene.add(ambientLight)


// const axesHelper = new THREE.AxesHelper(10)
// scene.add(axesHelper)

/**
* Renderer
*/
const renderer = new THREE.WebGLRenderer({
	canvas,
	alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// Render loop
/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const raycaster = new THREE.Raycaster()

const tick = () => {
	const elapsedTime = clock.getElapsedTime()
	const deltaTime = elapsedTime - previousTime
	previousTime = elapsedTime

	// Raycasting stuff
	const vector     = new THREE.Vector3(cursor.x, cursor.y, 1)
	raycaster.setFromCamera(vector, camera)
	const intersects = raycaster.intersectObjects(scene.children)

	if(isRoomLoaded) {
		const isRaycastingDesk     		= raycaster.intersectObject(workingDesk)
		const isRaycastingCreativeBench = raycaster.intersectObject(creativeBench)

		if(isRaycastingDesk.length > 0) {
			workingDeskGlow.forEach(glowy => glowy.visible = true)
			intersectingWith = 'workingDesk'
		} else if(isRaycastingCreativeBench.length > 0) {
			creativeBenchGlow.forEach(glowy => glowy.visible = true)
			intersectingWith = 'creativeBench'
		} else {
			creativeBenchGlow.forEach(glowy => glowy.visible = false)
			workingDeskGlow.forEach(glowy => glowy.visible = false)
			intersectingWith = null
		}
	}

	// Render
	renderer.render(scene, camera)

	// Call tick again on the next frame
	window.requestAnimationFrame(tick)
}

tick()